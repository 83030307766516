import { ICountry } from "@/interfaces/ICountry";
import {
  infoWithImage,
  TableAction,
  tableCellType
} from "@/components/Table/ts/interfaces/common";
import {
  AccountStatus,
  AdminStatus
} from "@/store/interfaces/accounts/IUserAccount";
import { sortOrders } from "@/enums/main/sortOrders";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import { TBadgeSize, TBadgeType } from "@/components/ui/AppBadge/interfaces";

export type cellLabel = string;
export type cellCheckmark = boolean;
export type cellCountry = ICountry;
export type cellInfoWithImage = infoWithImage;
export type cellBadge = {
  label: string;
  type: TBadgeType;
  size?: TBadgeSize;
  rounded?: boolean;
};

export interface ITableComponentNames {
  cellLabel: string;
  cellCheckmark: string;
  cellCountry: string;
  cellInfoWithImage: string;
  userActions: string;
  cellBadge: string;
}

export const tableComponentNames: ITableComponentNames = {
  cellLabel: "cellLabel",
  cellCheckmark: "cellCheckmark",
  cellCountry: "cellCountry",
  cellInfoWithImage: "cellInfoWithImage",
  userActions: "userActions",
  cellBadge: "cellStatus"
};

export type tableContentItem = cellLabel | cellCheckmark | cellInfoWithImage;

export type tableComponentName =
  | "cellLabel"
  | "cellCheckmark"
  | "cellCountry"
  | "cellInfoWithImage"
  | "userActions"
  | "cellBadge";

export interface ITableBodyCell {
  label?: cellLabel;
  checkmark?: cellCheckmark;
  country?: cellCountry;
  infoWithImage?: cellInfoWithImage;
  badge?: cellBadge;
  isStatusText?: boolean;
  statusCell?: boolean;
  userActions?: boolean;

  rightAlign?: boolean;
  type?: tableCellType;
  actions?: TableAction[];
  onClick?: void;
  sort?: {
    keyName: string;
    value: string | number;
    order: sortOrders.asc | sortOrders.desc | sortOrders.turnOff;
  };
}

export enum tableRowStatuses {
  warning = "warning",
  danger = "danger",
  base = ""
}

export type tableRowBgStatus = "warning" | "danger" | "";
export interface ITableBodyRow {
  onClick?: () => void;
  cells: ITableBodyCell[];
  status?: AccountStatus | AdminStatus | string;
  actions?: TableAction[];
  id?: number;
  bgStatus?: tableRowBgStatus;
}

export type cellSize =
  | "xxs"
  | "xs"
  | "sm"
  | "smL"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "fluid";

export type ITableHeadCell = {
  size: cellSize;
  label: string;
  sort?: {
    keyName: string;
    order: sortOrders.turnOff | sortOrders.asc | sortOrders.desc;
  }; // key name for sorting
  id: string;
  rightAlign?: boolean;
};

export interface ITableHeadTab extends IObjectKeys {
  id: number;
  name: string;
}
